import "./HowTo01.css";

function HowTo01() {
  return (
    <div className="HowTo01 section">
      <div className="container">
        <div className="HowTo01-description">
          <h2>How To Buy ?</h2>
        </div>

        <div className="HowTo01-box">
          <div>
            <h3>Step 1</h3>
            <p> Get a crypto wallet from Phantom</p>
          </div>
          <div>
            <h3>Step 2</h3>
            <p> Bridge from ANY chain to Solana.</p>
          </div>
          <div>
            <h3>Step 3</h3>
            <p> Buy $FRANK on a DEX like Raydium.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowTo01;
