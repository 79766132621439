import "./Hero01.css";
import pic01 from "../img/cont/tweet-01.png";
import pic02 from "../img/cont/tweet-02.png";
import pic03 from "../img/cont/tweet-03.png";

function Hero01() {
  return (
    <div className="Hero01 section">
      <div className="container">
        <div className="Hero01-box">
          <div className="Hero01-box-text">
            <h1>MEET $FRANK !</h1>
            <p>
              $FRANK is not just a memecoin; it's a community-driven movement
              that aims to bring fun and excitement to the world of
              cryptocurrency. With its adorable simple mascot, $FRANK is poised
              to become the next big thing in the crypto space.
            </p>

            <p className="ca">
              CA: 28ibpd2KAgvcbxThL1DmYT28GtUw6PRBVLQkQuwepump
            </p>

            <div className="btnFlex">
              <a
                href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&fixed=in&inputMint=sol&outputMint=28ibpd2KAgvcbxThL1DmYT28GtUw6PRBVLQkQuwepump"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn04">BUY NOW</button>
              </a>
              <a
                href="https://www.dextools.io/app/en/solana/pair-explorer/AvXueSkRm6Genrw6Jk1XvAT8gSJeo19inXg5Kok7ZwPb?t=1731890078148"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn03">VIEW CHART</button>
              </a>
            </div>
          </div>

          <div className="Hero01-box-images">
            <img src={pic02} alt="picture"></img>
            <img src={pic01} alt="picture"></img>
            <img src={pic03} alt="picture"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero01;
