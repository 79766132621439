import "./Why01.css";

import icon01 from "../img/cont/community.png";
import icon02 from "../img/cont/rewards.png";
import icon03 from "../img/cont/security.png";

function Why01() {
  return (
    <div className="Why01 section">
      <div className="container">
        <div className="Why01-box">
          <div>
            <img src={icon01} alt="picture"></img>
            <h2>Security</h2>
            <p>
              We burned the LP tokens to prevent any unauthorized access and
              guarantee that funds remain secure.
            </p>
          </div>

          <div>
            <img src={icon02} alt="picture"></img>
            <h2>Community</h2>
            <p>
              We encourage active participation through regular events,
              competitions, and discussions.
            </p>
          </div>

          <div>
            <img src={icon03} alt="picture"></img>
            <h2>Zero Tax</h2>
            <p>We have implemented a zero transaction fee policy.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why01;
