import "./Navbar01.css";
import picture01 from "../img/logo/favicon.png";

function Navbar01() {
  return (
    <header className="Navbar01">
      <div className="Navbar01-logo">
        <img src={picture01} alt="picture"></img>
        <p>$FRANK Memecoin</p>
      </div>

      <ul className="Navbar01-links">
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#token">Token</a>
        </li>
        <li>
          <a href="#join">Community</a>
        </li>
      </ul>
    </header>
  );
}

export default Navbar01;
