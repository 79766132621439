import "./About01.css";

function About01() {
  return (
    <div id="about" className="About01 section">
      <div className="container">
        <div className="About01-box">
          <div>
            <h2>About $FRANK</h2>
            <p>
              $FRANK is a decentralized cryptocurrency built on the innovative
              Solana blockchain. Our mission is to create a fun and inclusive
              community where everyone can participate in the future of finance.
              We believe that cryptocurrency should be accessible to all, and
              $FRANK is the perfect way to get started.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About01;
