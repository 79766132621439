import "./Token01.css";

function Token01() {
  return (
    <div id="token" className="Token01 section">
      <div className="container">
        <div className="Token01-box">
          <div>
            <h2>Tokenomics</h2>
            <p>
              To ensure a fair and transparent environment for all, $FRANK
              features a zero-tax structure with burnt LP, providing a solid
              foundation for long-term growth and stability.
            </p>
            <div className="btnFlex">
              <a
                href="https://solscan.io/token/28ibpd2KAgvcbxThL1DmYT28GtUw6PRBVLQkQuwepump"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn03">SOLSCAN</button>
              </a>
              <a
                href="https://www.dextools.io/app/en/solana/pair-explorer/AvXueSkRm6Genrw6Jk1XvAT8gSJeo19inXg5Kok7ZwPb?t=1731890078148"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn04">DEXTOOLS</button>
              </a>
            </div>
          </div>

          <div>
            <div className="ca">
              <h3>CA:</h3>
              <p>28ibpd2KAgvcbxThL1DmYT28GtUw6PRBVLQkQuwepump</p>
            </div>
            <div>
              <h3>Symbol:</h3>
              <p>$FRANK</p>
            </div>
            <div>
              <h3>Tax:</h3>
              <p>0%</p>
            </div>
            <div>
              <h3>Supply:</h3>
              <p>1 Billion</p>
            </div>
            <div>
              <h3>LP:</h3>
              <p>Burnt</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Token01;
