import "./Join01.css";
import pic01 from "../img/logo/logo.png";

function Join01() {
  return (
    <div id="join" className="Join01 section">
      <div className="container">
        <div className="Join01-box">
          <div>
            <h2>Join the $FRANK Fam!</h2>
            <p>
              Join our growing community of $FRANK enthusiasts! Connect with
              fellow holders, stay up-to-date on the latest news, and
              participate in exciting community events.
            </p>
            <div className="btnFlex">
              <a
                href="https://x.com/FrankCoinOnSol"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn02">X/TWITTER</button>
              </a>
              <a
                href="https://t.me/frankcoinsol"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn02">TELEGRAM</button>
              </a>
            </div>
          </div>

          <img src={pic01} alt="picture"></img>
        </div>
      </div>
    </div>
  );
}

export default Join01;
